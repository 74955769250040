<template>
  <template v-if="isThereValue">
    <slot />
  </template>
  <template v-else-if="isEditable">{{ EMPTY_VALUE_PLACEHOLDER }}</template>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { Entity } from '@/entities/utils/common'

import { EMPTY_VALUE_PLACEHOLDER } from '@/const/common'

import { getSlotName } from '../utils/helpers'

type Props = {
  instance: Entity<any>
  name: string
  value: any
}

const props = defineProps<Props>()

defineOptions({
  name: 'GridBunchCellValue',
})

const isEditable = computed(() => {
  const formKey = getSlotName(props.name, 'getForm') as keyof Entity<any>
  const emptyKey = getSlotName(props.name, 'empty') as keyof Entity<any>
  return !props.instance[emptyKey] && props.instance[formKey]
})

const isThereValue = computed(() => !!props.value)
</script>
