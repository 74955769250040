<template>
  <component
    :is="component()"
    v-if="component"
    v-bind="{ instance }"
    size="sm"
    :silent="false"
    @mouseup.stop
    @mousedown.stop
    @click:enter="handleEditClickEnter"
    @click:tab="handleEditClickTab"
    @click:escape="handleEditClickEscape"
    @ready="onReady"
  />
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue'

import { Entity } from '@/entities/utils/common'
import { getSlotName } from '../utils/helpers'

type Props = {
  instance: Entity<any>
  name: string
}

type Emits = {
  'click:enter': []
  'click:tab': []
  'click:escape': []
  reset: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'GridBunchEditComponent',
})

const component = computed(() => {
  const formKey = getSlotName(props.name, 'getForm') as keyof Entity<any>
  const emptyKey = getSlotName(props.name, 'empty') as keyof Entity<any>
  return props.instance[emptyKey] ? undefined : props.instance[formKey]
})

const handleEditClickEnter = (event: Event) => {
  event.stopPropagation()
  emit('click:enter')
}

const handleEditClickTab = (event: Event) => {
  event.stopPropagation()
  event.preventDefault()
  emit('click:tab')
}

const handleEditClickEscape = (event: Event) => {
  event?.stopPropagation()
  event?.preventDefault()
  emit('click:escape')
}

const onReady = (e: any) => {
  e.$el ? e.$el.focus() : e.focus()
}

onBeforeMount(() => {
  if (component.value) return
  emit('reset')
})
</script>
