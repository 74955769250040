<template>
  <LockClosedIcon
    aria-hidden="true"
    class="ui-grid__lock"
    :class="mainClasses"
    @mouseup.stop
    @mousedown.stop
  />
</template>

<script setup lang="ts">
import { Ref, computed, inject } from 'vue'

import { LockClosedIcon } from '@heroicons/vue/20/solid'

type Props = {
  active?: boolean
}

const props = defineProps<Props>()

defineOptions({
  name: 'GridBunchLock',
})

const isHovered = inject<Ref<boolean>>('isHovered')

const mainClasses = computed(() => ({
  'ui-grid__lock--hovered': isHovered?.value,
  'ui-grid__lock--active': props.active,
}))
</script>

<style scoped lang="postcss">
.ui-grid__lock {
  @apply w-4 h-4;
  @apply text-gray-200 dark:text-gray-300;

  &--hovered {
    @apply text-gray-300 dark:text-gray-400;
  }

  &--active {
    @apply !text-indigo-200 dark:!text-indigo-300;
  }
}
</style>
