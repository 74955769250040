<template>
  <UIPanel
    v-model="clearedSearch"
    v-model:storage="searchStorage"
    v-bind="{ disabled, placeholder }"
    fill-on-mobile
  >
    <template
      #default="{
        ButtonCancel,
        ButtonDelete,
        ButtonDuplicate,
        ButtonExport,
        ButtonExportAdvanced,
        ButtonImport,
        ButtonNew,
        ButtonSaveChanges,
        size,
      }"
    >
      <UIFilter
        v-model="clearedFilter"
        v-model:storage="filterStorage"
        v-bind="{ disabled, size }"
        :fields="filterFields"
        flat
      />
      <UISort
        v-model="clearedSort"
        v-model:storage="sortStorage"
        v-bind="{ disabled, size }"
        :fields="sortFields"
      />

      <slot name="panel" v-bind="{ size }" />

      <template v-if="hasSelectedItems">
        <component :is="ButtonDuplicate" @click="handleClickDuplicate" />
        <component :is="ButtonDelete" @click="handleClickDelete" />
      </template>

      <template v-if="dirty">
        <component :is="ButtonSaveChanges" @click="handleClickSave" />
        <component :is="ButtonCancel" @click="handleClickCancel" />
      </template>

      <template v-if="isExportButtonVisible">
        <component
          :is="ButtonExportAdvanced"
          v-if="isAdvancedExport"
          v-bind="{ exportAllLabel }"
          @export:all="handleClickExport"
          @export:selected="handleExportSelected"
        />
        <component :is="ButtonExport" v-else @click="handleClickExport" />
      </template>
      <component :is="ButtonImport" @click="handleClickImport" />

      <slot name="new">
        <component :is="ButtonNew" @click="handleClickCreate" />
      </slot>
    </template>
  </UIPanel>
  <GridSkeleton
    v-if="loading"
    v-bind="{ footer, sm }"
    :columns="columnsList"
    :message="loadingMessage"
    selectable
    wrapped
  />
  <Grid
    v-else
    ref="gridRef"
    v-model:selected="selected"
    v-model:sort="sortStorage"
    v-bind="{
      ...attrs,
      footer,
      items,
      lazy,
      sm,
      valueGetter,
      valuePaster,
    }"
    :data-refid="dataRefid"
    :columns="columnsList"
    editable
    selectable
    scrollable
    wrapped
    @click:menu="handleClickMenu"
    @click:row="handleClickRow"
  >
    <template #checkbox="{ active, item }">
      <Lock v-if="item.isDisabled" v-bind="{ active }" />
    </template>
    <template #cellEdit="{ active, item }">
      <EditPencil
        v-bind="{ active }"
        :readonly="item.isReadonly"
        @click="handleClickEdit(item.id)"
      />
    </template>
    <template
      v-for="[name, slot] in slots"
      :key="slot"
      #[slot]="{ displayValue, editCell, item }"
    >
      <EditComponent
        v-if="editCell"
        :name
        :instance="item"
        @click:enter="handleEditClickEnter"
        @click:tab="handleEditClickTab"
        @click:escape="handleEditReset"
      />
      <CellValue
        v-else
        v-bind="{ name }"
        :instance="item"
        :value="displayValue"
      >
        <slot :name="slot" v-bind="{ displayValue, editCell, item }">
          {{ displayValue }}
        </slot>
      </CellValue>
    </template>
    <template #cellState="{ editCell, item }">
      <State
        v-bind="{ columns, item }"
        :edit="editCell"
        @release="handleEditReset"
      />
    </template>
  </Grid>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { Filter, FilterGroup, Sort } from '@types'
import { GridColumn, GridFooter, GridValuePaster } from './utils/types'
import { EntityState } from '@/entities/utils/enums'
import { Entity } from '@/entities/utils/common'

import { getSlotName } from './utils/helpers'

import { compareEntries } from '@/entities/transactions/components/utils/helpers'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useRepositoriesStore } from '@/store/repositories'

import { useNotifications } from '@/plugins/notification'

import CellValue from './components/BunchCellValue.vue'
import EditComponent from './components/BunchEditComponent.vue'
import EditPencil from './components/BunchEditPencil.vue'
import Grid from './Grid.vue'
import GridSkeleton from './GridSkeleton.vue'
import Lock from './components/BunchLock.vue'
import State from './components/BunchState.vue'
import { UIPanel } from '@ui/core'
import { UIFilter, UISort } from '@ui/controllers'

type GridBunchItem = Entity<any>

type Props = {
  columns: GridColumn[]
  items: GridBunchItem[]

  footer?: GridFooter

  disabled?: boolean
  readonly?: boolean

  activeId?: string | number

  sm?: string

  unitarySort?: boolean

  displayMenu?: boolean

  collapseWidth?: number

  lazy?: number

  // own props
  dataRefid?: string
  dirty: boolean

  disableSortState?: boolean
  permanentExport?: boolean
  exportAllLabel?: string

  placeholder?: string

  loading?: boolean
  loadingMessage?: string
  processing?: boolean
}

type Emits = {
  'click:menu': [data: GridBunchItem]
  'click:row': [data: GridBunchItem]

  'click:edit': [data: string]
  'click:duplicate': [data: string[]]
  'click:delete': [data: string[]]
  'click:save': []
  'click:cancel': []
  'click:export': []
  'click:export-selected': []
  'click:import': []
  'click:create': []
}

const {
  footer,

  dataRefid,
  dirty,

  disableSortState,
  permanentExport,

  placeholder,

  loading,
  loadingMessage,
  processing,

  columns,
  ...props
} = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  deselect() {
    gridRef.value?.deselect()
  },
  getContainer() {
    return gridRef.value
  },
})

defineOptions({
  name: 'UIGridBunch',
  inheritAttrs: false,
})

const selected = defineModel<string[]>('selected', { required: true })
const clearedSearch = defineModel<string | undefined>('search', {
  required: true,
})
const searchStorage = defineModel<string | undefined>('searchStorage', {
  required: true,
})

const clearedSort = defineModel<Sort[] | undefined>('sort', { required: true })
const sortStorage = defineModel<Sort[] | undefined>('sortStorage', {
  required: true,
})
const clearedFilter = defineModel<FilterGroup | undefined>('filter', {
  required: true,
})
const filterStorage = defineModel<Filter | undefined>('filterStorage', {
  required: true,
})

const assetsBunchStore = useAssetsBunchStore()
const repositoriesStore = useRepositoriesStore()
const { error } = useNotifications()

const gridRef = useTemplateRef('gridRef')

const attrs = computed(() => props)

const disabled = computed(() => processing || loading)

const filterableColumns = computed(() => [
  ...columns,
  {
    name: 'state',
    caption: 'State',
    unsortable: disableSortState,
    unresizeable: true,
    default: '5.5rem',
    headerClasses: 'ui-grid__header--state',
    cellClasses: 'ui-grid__cell--state',
    footerClasses: 'ui-grid__footer--state',
    filter: {
      isSimple: true,
      list: Object.entries(EntityState).map(([, value]) => ({
        value,
        label: value,
      })),
    },
    hideCaptionIfCollapsed: true,
  } as GridColumn,
])

const columnsList = computed(() => [
  {
    name: 'edit',
    caption: '',
    unsortable: true,
    unresizeable: true,
    silent: true,
    headerClasses: 'ui-grid__header--edit',
    cellClasses: `ui-grid__cell--edit ${loading ? 'ui-grid__cell--edit-loading' : ''}`,
    footerClasses: 'ui-grid__footer--edit',
    default: 'min-content',
  },
  ...filterableColumns.value,
])

const filterFields = computed(() =>
  filterableColumns.value
    .filter(column => !column.unsortable)
    .map(column => ({
      name: column.name,
      caption: column.caption,
      ...column.filter,
    })),
)

const sortFields = computed(() =>
  filterableColumns.value
    .filter(column => !column.unsortable)
    .map(column => ({
      value: column.filter?.name || column.name,
      label: column.filter?.caption || column.caption,
    })),
)

const slots = computed(() =>
  columns.map(column => [column.name, getSlotName(column.name, 'cell')]),
)

const hasSelectedItems = computed(() => !!selected.value.length)

const isExportButtonVisible = computed(() => {
  return props.items.length && (hasSelectedItems.value || permanentExport)
})

const isAdvancedExport = computed(() => {
  return hasSelectedItems.value && permanentExport
})

const currencyID = computed(() => {
  const asset =
    assetsBunchStore.getElementByName(
      repositoriesStore.getCurrentRepositoryCurrency,
    ) ||
    assetsBunchStore.getElementByTicker(
      repositoriesStore.getCurrentRepositoryCurrency,
    )
  return asset?.id
})

const valueGetter = (field: string, item: GridBunchItem) => {
  const displayKey = getSlotName(field, 'display') as keyof GridBunchItem
  return (displayKey && item[displayKey]) || item.field(field).value
}

const valuePaster: GridValuePaster = (field, item, data, check) => {
  if (item.isDisabled || item.isReadonly) return
  const pasteKey = getSlotName(field, 'paste') as keyof GridBunchItem
  if (check && pasteKey && item[pasteKey]) {
    const result = item[pasteKey](data)
    if (!result) {
      error({
        message: 'You cannot paste such data into this cell',
      })
    }
  } else {
    item.field(field).value = data

    if (field === 'type') {
      compareEntries(item, assetsBunchStore, currencyID.value)
    }
  }
}

const handleClickMenu = (data: any) => {
  emit('click:menu', data)
}

const handleClickRow = (data: any) => {
  emit('click:row', data)
}

const handleClickEdit = (data: string) => {
  emit('click:edit', data)
}

const handleClickDuplicate = () => {
  emit('click:duplicate', selected.value)
  gridRef.value?.deselect()
}

const handleClickDelete = () => {
  emit('click:delete', selected.value)
  gridRef.value?.deselect()
}

const handleClickSave = () => {
  emit('click:save')
}

const handleClickCancel = () => {
  emit('click:cancel')
}

const handleClickExport = () => {
  emit('click:export')
}

const handleExportSelected = () => {
  emit('click:export-selected')
}

const handleClickImport = () => {
  emit('click:import')
}

const handleClickCreate = () => {
  emit('click:create')
}

const handleEditClickEnter = () => {
  gridRef.value?.release()
  gridRef.value?.clickEnter()
}

const handleEditClickTab = () => {
  gridRef.value?.release()
  gridRef.value?.clickTab()
}

const handleEditReset = () => {
  gridRef.value?.release()
}
</script>

<style>
.ui-grid__cell {
  &--edit {
    .ui-grid__caption {
      @apply hidden;
    }

    .ui-grid__cell-value {
      @apply py-1 px-2 -mx-2 -my-1;
      @apply text-primary-selected;
      @apply cursor-pointer;

      &--truncated {
        @apply overflow-visible;
      }
    }
  }

  &--edit-loading {
    .ui-grid__cell-value {
      @apply min-w-12;
      @apply p-0 m-0;
      @apply bg-transparent;
    }
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__header,
  .ui-grid__cell,
  .ui-grid__footer {
    &--edit {
      @apply !px-0;
    }
    &--state {
      @apply !pr-2;
    }
  }

  .ui-grid__cell {
    &--edit {
      .ui-grid__cell-value {
        @apply p-0 m-0;
        @apply bg-transparent dark:bg-transparent;
      }
    }
  }
}

.ui-grid:is(.ui-grid--collapsed) {
  .ui-grid__cell {
    &--state {
      .ui-grid__cell-value--truncated {
        @apply overflow-visible;
      }
      span {
        @apply py-0.5 px-2;
        @apply rounded-full;
        @apply bg-gray-50 dark:bg-gray-800;
      }
    }
  }
}
</style>
