<template>
  <div class="ui-grid__edit" :class="mainClasses">
    <component
      :is="icon"
      aria-hidden="true"
      class="ui-grid__edit-icon"
      @mouseup.stop
      @mousedown.stop
    />
    <span class="ui-grid__edit-text">Edit</span>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject } from 'vue'

import { EyeIcon, PencilIcon } from '@heroicons/vue/24/outline'

type Props = {
  active?: boolean
  readonly?: boolean
}

const props = defineProps<Props>()

defineOptions({
  name: 'GridBunchEditPencil',
})

const isHovered = inject<Ref<boolean>>('isHovered')
const isReadonly = inject<boolean>('isReadonly')

const readonly = computed(() => isReadonly || props.readonly)
const icon = computed(() => (readonly.value ? EyeIcon : PencilIcon))

const mainClasses = computed(() => ({
  'ui-grid__edit--hovered': isHovered?.value,
  'ui-grid__edit--active': props.active,
}))
</script>

<style scoped lang="postcss">
.ui-grid__edit {
  @apply flex items-center;
  @apply gap-1;

  &--hovered {
    @apply text-indigo-500 hover:text-indigo-700;
    @apply dark:text-indigo-400 dark:hover:text-indigo-300;
  }

  &--active {
    @apply !text-indigo-400 dark:!text-indigo-300;
  }

  &-text {
    @apply font-medium uppercase;
    @apply tracking-wider;
  }

  &-icon {
    @apply w-3 h-3;
  }
}

.ui-grid:not(.ui-grid--collapsed) {
  .ui-grid__edit {
    @apply invisible;

    &--hovered,
    &--active {
      @apply visible;
    }

    &-text {
      @apply hidden;
    }

    &-icon {
      @apply w-4 h-4;
    }
  }
}
</style>
