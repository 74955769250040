<template>
  <UIDropdown
    v-if="displayErrors.length"
    placement="bottom-end"
    @mousedown.stop
  >
    <template #default="{ toggle }">
      <div class="ui-grid__state" :class="mainClasses" @click="toggle">
        <span>{{ displayValue }}</span>
        <QuestionMarkCircleIcon
          class="ui-grid__state-icon"
          aria-hidden="true"
        />
      </div>
    </template>
    <template #popper>
      <div
        v-for="error in displayErrors"
        :key="error"
        class="ui-grid__state-error"
      >
        {{ error }}
      </div>
    </template>
  </UIDropdown>
  <span v-else :class="mainClasses">{{ displayValue }}</span>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import { GridColumn } from '../utils/types'
import { Entity } from '@/entities/utils/common'

import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import { UIDropdown } from '@ui/dropdowns'

type Props = {
  columns: GridColumn[]

  item: Entity<any>

  edit?: boolean
}

type Emits = {
  release: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'GridBunchState',
})

const displayValue = computed(() => props.item.state)

const mainClasses = computed(() => ({
  [`ui-grid__state--${props.item.state}`]: props.item.state,
}))

const displayErrors = computed(() =>
  Object.entries(props.item.errors).map(([key, value]) => {
    const result = []
    const field =
      props.columns?.find(column => column.name === key)?.caption || key
    result.push(field)
    result.push(value)
    return result.join(': ')
  }),
)

watch(
  () => props.edit,
  value => {
    if (!value) return
    emit('release')
  },
)
</script>

<style>
.ui-grid__state {
  @apply flex items-center;
  @apply space-x-1;

  &--add {
    @apply text-yellow-700 dark:text-yellow-500;
  }

  &--update {
    @apply text-yellow-500 dark:text-yellow-400;
  }

  &--invalid {
    @apply text-red-500 dark:text-red-400;
  }

  &--errors {
    @apply text-red-400 dark:text-red-300;
  }

  &--delete {
    @apply text-red-800 dark:text-red-700;
  }

  &-icon {
    @apply w-4 h-4;
    @apply shrink-0;
    @apply cursor-pointer;
  }

  &-error {
    @apply max-w-56;
    @apply min-w-40;
    @apply p-1 px-3;
    @apply text-xs;
    @apply text-light-text;
  }
}
</style>
